import React from "react";
import { StaticWrapper, ContentWrapper, Bold, ContentParagraph, InParagraphLink } from "./promotionsHelpers";

const ManualPromotion = () => {
  return (
    <StaticWrapper $color="#fff">
      <ContentWrapper >
        <ContentParagraph color="#000">
          <Bold>💥❣️ NOWOŚĆ ❣️💥</Bold> <InParagraphLink to="/sklep/atop-stop-balsam-do-ciala/">ATOP STOP</InParagraphLink> balsam do ciała z biofementem i mocznikiem  </ContentParagraph>
      </ContentWrapper>
    </StaticWrapper>
  );
};

export default ManualPromotion;
